import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Menu from '../components/menu'
import Footer from './footer'
import './index.css'

class Layout extends React.Component {
  componentDidMount() {
    const { location } = this.props
    if (location) {
      const { hash } = location
      if (hash) {
        this.scrollToSection(hash)
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props
    if (location !== nextProps.location) {
      const { hash } = nextProps.location
      this.scrollToSection(hash)
    }
  }

  scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      window.scroll({
        top: section.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  render() {
    const { children } = this.props
    return (
      <StaticQuery
        query={graphql`
          query NavBarQuery {
            navBar: allContentfulNavBar {
            edges {
              node {
                id
                items {
                  id
                  title
                  link
                  items {
                    id
                    title
                    link
                  }
                }
                logo {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
        `}
        render={(data) => {
          const { items, logo } = data.navBar.edges[0].node
          return (
            <div>
              <Menu items={items} logo={logo.file.url} />
              <div>
                {children}
              </div>
              <Footer />
              <link href='https://fonts.googleapis.com/css?family=Roboto:400,700' rel='stylesheet' />
            </div>)
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  location: PropTypes.object,
}

export default Layout
