import React from 'react'

import './contact.scss'

const ContactDetails = () => (
  <div className='contact-details'>
    <p>77 New Windsor Rd, New Windsor, Auckland, 0600</p>
    <p>
      <a href='tel:0800665506'>0800 665 506</a> | <a href='mailto:info@hygienz.co.nz'>info@hygienz.co.nz</a>
    </p>
  </div>
)

export default ContactDetails
