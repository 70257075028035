import React from 'react'
import PropTypes from 'prop-types'

import Dropdown from './dropdown'
import './menu.scss'
import Button from '../button'

class Menu extends React.Component {
  constructor() {
    super()
    this.state = { open: false }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getLogo = () => {
    const { logo } = this.props
    return <a href='/'><img src={logo} alt='logo' className='menu-logo' /></a>
  }

  getMenuItem = (item, isBurgerMenu) => (
    <a
      key={item.id}
      onClick={isBurgerMenu && this.toggleMenu}
      href={`${item.link}${item.scrollTo ? `${item.scrollTo}` : ''}`}
      className='menu-link'
    >
      {item.title}
    </a>
  )

  getMenuItems = (isBurgerMenu) => {
    const { items } = this.props
    return (items.map((item) => {
      if (item.items) {
        return (
          <Dropdown key={item.id} item={item}>
            {item.items.map((innerItem) => (
              <li key={innerItem.id}>{this.getMenuItem(innerItem, isBurgerMenu)}</li>
            ))}
          </Dropdown>
        )
      }
      return this.getMenuItem(item, isBurgerMenu)
    }))
  }

  toggleMenu = () => {
    const { open } = this.state
    if (!open) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }
    this.setState({ open: !open })
  }

  getPhoneItem = () => <a className='phone-number' href='tel:0800665506'>0800 665 506</a>

  handleScroll = () => {
    const { menuBackClass } = this.state
    if (window.scrollY <= 20 && menuBackClass !== 'menu-pinned-back') {
      this.setState({ menuBackClass: 'menu-pinned-back' })
    } else if (window.scrollY > 20 && menuBackClass !== 'menu-background') {
      this.setState({ menuBackClass: 'menu-background' })
    }
  }

  render() {
    const { open, menuBackClass } = this.state
    if (!open) {
      return (
        <div className='headroom-container'>
          <div className={menuBackClass}>
            <div className='page-container menu-container'>
              {this.getLogo()}
              <div className='menu-items-container'>
                {this.getMenuItems()}
                {this.getPhoneItem()}
                <Button tertiary to='/free-quote'>Get a Free Quote</Button>
              </div>
            </div>
            <div className='menu-burger'>
              {this.getLogo()}
              <div className='cta-and-menu-icon'>
                <div className='cta-buttons'>
                  {this.getPhoneItem()}
                  <Button tertiary to='/free-quote'>Get a Free Quote</Button>
                </div>
                <button className='menu-burger-button' type='button' onClick={this.toggleMenu}>&#9776;</button>
              </div>
            </div>
          </div>
        </div>)
    }
    return (
      <div className='menu-overlay'>
        <div className='overlay-header'>
          {this.getLogo()}
          {this.getPhoneItem()}
          <div className='cta-overlay'><Button tertiary to='/free-quote'>Get a Free Quote</Button></div>
          <button href type='button' className='menu-closebtn' onClick={this.toggleMenu}>&times;</button>
        </div>
        <div className='menu-overlay-content'>
          {this.getMenuItems(true)}
        </div>
      </div>)
  }
}

Menu.propTypes = {
  logo: PropTypes.string,
  items: PropTypes.array,
}

export default Menu
