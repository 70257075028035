import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import './button.scss'

/**
 * flashy: boolean - adds flashy button usually used in home hero for cta
 * @param {*} param0
 * @returns
 */
const Button = ({
  children, isButton, className, flashy, secondary, tertiary, ...rest
}) => {
  if (isButton) {
    return (
      <button type='button' className={`hype-button ${className}`} {...rest}>
        {children}
      </button>)
  }

  let classNames = `hype-button ${secondary && 'hype-button-secondary'} ${tertiary && 'hype-button-tertiary'} ${className}`
  if (flashy) {
    classNames = `hype-button flashy ${className}`
  }
  return (
    <Link className={classNames} {...rest}>
      {children}
    </Link>)
}

Button.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  isButton: PropTypes.bool,
}

export default Button
