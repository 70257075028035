import React from 'react'

import './footer.scss'

import FbIcon from '../../static/images/fb-icon.svg'
import IgIcon from '../../static/images/ig-icon.svg'
import PhoneIcon from '../../static/images/phone-icon.svg'
import MailIcon from '../../static/images/mail-icon.svg'

const Footer = () => (
  <div className='footer-background'>
    <div className='footer-container page-container'>
      <div>
        <div className='social-icons'>
          <a href='https://www.facebook.com/profile.php?id=61567516269350' target='_blank' rel='noopener noreferrer'>
            <FbIcon />
          </a>
          <a href='https://www.instagram.com/hygienzcleaning' target='_blank' rel='noopener noreferrer'>
            <IgIcon />
          </a>
          <a href='tel:0800665506'><PhoneIcon /></a>
          <a href='mailto:info@hygienz.co.nz'><MailIcon /></a>
        </div>
      </div>
      <div>
        <div>© {new Date().getFullYear()} HYGIENZ. All rights reserved.</div>
        <div>
          <small>
            Website built by
            <a href='http://thehype.nz' target='_blank' rel='noopener noreferrer'>
              The Hype - Digital Agency
            </a>
          </small>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
