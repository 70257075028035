export const CONTACT_FORM = {
  category: 'Contact form',
  successSubmitAction: 'Success submit',
  errorSubmitAction: 'Error on submit form',
}

export const FREE_QUOTE_FORM = {
  category: 'Free quote form',
  successSubmitAction: 'Success submit',
  errorSubmitAction: 'Error on submit form',
}

export const WORK_WITH_US_FORM = {
  category: 'Work with us form',
  successSubmitAction: 'Success submit',
  errorSubmitAction: 'Error on submit form',
}
